import {Injectable} from '@angular/core';
import {DefaultService} from '@core/services/default.service';

@Injectable({
  providedIn: 'root'
})
export class UsersService extends DefaultService {
  formName: '#users';

  getUrl(): string {
    return 'api/v1/users';
  }
}
